<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput size="sm" v-model="vendor_name" placeholder="Name"/>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="vendorTypeList.data"
            :reduce="item => item.id"
            v-model="vendor_type"
            placeholder="Vendor Type"
          > 
          </v-select>   
        </CCol>
        <CCol lg="3">
          <CInput size="sm" v-model="address_a" placeholder="Address"/>
        </CCol>
       
        <CCol lg="3">
          <CInput size="sm" v-model="contact_no" placeholder="Contact No."/>
        </CCol>
        <CCol lg="3">
          <CInput size="sm" v-model="email" placeholder="Email"/>
        </CCol>
       
        
        <CCol lg="3" class="float-right mt-1">
          <CButton size="sm" color="info" variant="outline" style="margin-right: 10px"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-right: 10px"  >Clear Filter</CButton>
         
        </CCol>
       
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            vendor_name: "",
            vendor_type: "",
            email: "",
            address_a: "",
            contact_no: "", 
            vendorTypeList: {
              data: []
            }
          }
        },
      mounted() {
        this.getVendorType();
      },
      components: {vSelect},
      methods: {
        search() {
          const data = {
            vendor_name: this.vendor_name,
            vendor_type: this.vendor_type,
            email: this.email,
            address_a: this.address_a,
            contact_no: this.contact_no,
            account_code: this.account_code,
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.vendor_name = "";
          this.vendor_type = "";
          this.email = "";
          this.address_a = "";
          this.contact_no = "";
          this.account_code = "";
          const data = {
            vendor_name: this.vendor_name,
            vendor_type: this.vendor_type,
            email: this.email,
            address_a: this.address_a,
            contact_no: this.contact_no,
            account_code: this.account_code,
          }
          this.$emit("depot-search-query", data)
        },
        updateVendorType(data){
          this.vendor_type = data;
        },
        getVendorType(){

          axios.get(config.api_path+'/setting?setting_type=vendor_type&page='+this.currentPage+'&limit=10000000')
          .then(response => {
            this.vendorTypeList = response.data; 
            this.vendorTypeList.data.unshift({
              id: "all",
              setting_name: "All"
            })
          })

        },
      }
    }
</script>

<style scoped>

</style>
